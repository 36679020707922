import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import PrimaryAppBar from "../components/appBar";
import { useNavigate } from "react-router-dom";

export default function Payments(){

    const navigate = useNavigate();

    const [saveEdit, SetSaveEdit] = useState(true)

    const saveEditHandler = () =>{
        SetSaveEdit(!saveEdit)
    }

    return(
        <div>
            <PrimaryAppBar/>
            <h2 className="header-Text">Payment Info</h2>
            <div className="event-manage-body">
            <Paper sx={{width: '90%', overflow: 'hidden', padding: '10px' }} elevation={3}>
            <Grid container spacing={3} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                <TextField
                    sx={{ width: '100%' }}
                    margin="normal"
                    required
                    fullWidth
                    id="accountName"
                    label="Account Name"
                    name="accountName"
                    // autoComplete="email"
                    autoFocus
                    disabled={saveEdit}
                />
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                <TextField
                    sx={{ width: '100%' }}
                    margin="normal"
                    required
                    fullWidth
                    id="accountName"
                    label="Account Number"
                    name="accountName"
                    // autoComplete="email"
                    // autoFocus
                    disabled={saveEdit}
                />
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                <TextField
                    sx={{ width: '100%' }}
                    margin="normal"
                    required
                    fullWidth
                    id="accountName"
                    label="Bank Name"
                    name="accountName"
                    // autoComplete="email"
                    // autoFocus
                    disabled={saveEdit}
                />
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                <TextField
                    sx={{ width: '100%' }}
                    margin="normal"
                    required
                    fullWidth
                    id="accountName"
                    label="Branch Name"
                    name="accountName"
                    // autoComplete="email"
                    // autoFocus
                    disabled={saveEdit}
                />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ width: '80%', paddingTop: '15px' }}>
                    <Grid item xs={6}>
                    <Stack spacing={2} direction="row">
                        <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        {saveEdit?
                        <Button variant="contained" color="info" onClick={saveEditHandler}>
                            Edit
                        </Button> :
                        <Button variant="contained" color="success" onClick={saveEditHandler}>
                            Save
                        </Button>                        
                        }
                    </Stack>
                    </Grid>
                 </Grid>
            </Paper>
            </div>

        </div>
    )
}