import React, { Component, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PrimaryAppBar from "../components/appBar";
import Login from "../Login/login";

export default function Home() {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const token1 = localStorage.getItem("accessToken");
    if (!token1) {
      navigate("/login");
    }
  });
  return (
    <div>
      <PrimaryAppBar />
      <h4 className="header-Text">Dashboard</h4>
    </div>
  );
}
