import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Placeholder from "../../assets/images/placeholder.png";

import PrimaryAppBar from "../components/appBar";

import { GetMyEvents } from "../../api-services/apiServices";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    fontWeight: "bold",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Events() {
  const [eventList, setEventsList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addNewHandle = () => {
    localStorage.setItem("eventId", 0);
    localStorage.setItem("addEdit", "Add New");
    navigate("/ManageEvent");
  };

  const editHandle = (id) => {
    // console.log(id)
    localStorage.setItem("eventId", id);
    localStorage.setItem("addEdit", "Edit");
    navigate("/ManageEvent");
  };

  useEffect(() => {
    let mounted = true;
    GetMyEvents().then((data) => {
      if (mounted) {
        setEventsList(data.data);
        // console.log('data')
        setDataLoaded(true);
      }
    });
    //   console.log(eventList)
    return () => {
      mounted = false;
    };
  }, []);

  if (!dataLoaded) {
    return (
      <div style={{ paddingTop: "50px" }}>
        <PrimaryAppBar />
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "50px" }}
        >
          <CircularProgress />
        </Box>
      </div>
    );
  } else {
    return (
      <div>
        <PrimaryAppBar />
        <h2 className="header-Text">My Events</h2>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddCircleIcon />}
          sx={{ float: "right", marginRight: "50px" }}
          onClick={addNewHandle}
          disabled
        >
          Add Event
        </Button>
        <div className="table-body">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 540 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell>Event Name</StyledTableCell>
                  <StyledTableCell>Poster</StyledTableCell>
                  <StyledTableCell>Summary</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Restricted</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => (
                    <TableRow hover tabIndex={-1}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <img
                          src={HTMLImageElement.complete ? row.thumbnail : Placeholder}
                          alt="poster"
                          width="120px"
                        />
                      </TableCell>
                      <TableCell>{row.summary}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Switch defaultChecked={row.restricted} disabled />
                      </TableCell>
                      <TableCell>
                        <ButtonGroup aria-label="outlined primary button group">
                          <IconButton
                            aria-label="edit"
                            disabled
                            color="primary"
                            onClick={() => editHandle(row.id)}
                          >
                            <BorderColorIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            color="error"
                            disabled
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Switch
                            defaultChecked={true}
                            color="success"
                            disabled
                          />
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </TableContainer>
          </Paper>
        </div>
      </div>
    );
  }
}
