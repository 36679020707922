import * as React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoginCall } from "../../api-services/apiServices";
import Skeleton from "@mui/material/Skeleton";
import bgImg from "../../assets/images/starwar.jpg";

import Home from "../Home/home";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://tickets.lk/">
        TicketsLK
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

let dataLoaded = false;

const Login = () => {
  const navigate = useNavigate();

  const [loginResponse, setLoginResponse] = React.useState([]);
  const [diableBtn, setDisableBtn] = React.useState(false);
  const [errorData, setErrorData] = React.useState(false);

  const handleSubmit = async (event) => {
    setDisableBtn(true);
    setErrorData(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    // });

    localStorage.setItem("userName", data.get("email"));
    localStorage.setItem("password", data.get("password"));

    let mounted = true;

    await LoginCall().then((data) => {
      if (mounted) {
        setLoginResponse(data);
        dataLoaded = true;
      }
      console.log(data.success);
      if (data.success !== 401) {
        localStorage.setItem("tokenExpire", data.data.expires_in);
        localStorage.setItem("refreshToken", data.data.refresh_token);
        localStorage.setItem("accessToken", data.data.access_token);
        navigate("/");
      } else {
        setDisableBtn(false);
        setErrorData(true);
      }
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${bgImg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              // padding: 24,
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <MovieFilterIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Event Organizers Portal
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={errorData}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={errorData}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" disabled/>}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={diableBtn}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2" disabled>
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2" disabled>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default Login;
