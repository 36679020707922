import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import Home from './Pages/Home/home';
import Login from './Pages/Login/login';
import Reports from './Pages/Reports/reportsView';
import Events from './Pages/myEvents/events';
import ManageEvents from './Pages/myEvents/addEditEvent';
import Payments from './Pages/payment-info/paymentInfo';
import IssueTickets from './Pages/issue-Tickets/issueTickets';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='login' element={<Login/>} />
        <Route path='/reports' element={<Reports/>} />
        <Route path='/events' element={<Events/>} />
        <Route path='/ManageEvent' element={<ManageEvents/>} />
        <Route path='/paymentInfo' element={<Payments/>} />
        <Route path='/issueTickets' element={<IssueTickets/>} />
      </Routes>
    </div>
  );
}

export default App;
