import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';

import { AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { styled } from '@mui/material/styles';

import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PhotoIcon from '@mui/icons-material/Photo';
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import PrimaryAppBar from "../components/appBar";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

var type = ''

const Input = styled('input')({
    display: 'none',
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export default function ManageEvents(){
    const eventId = localStorage.getItem('eventId')
    const addEdit = localStorage.getItem('addEdit')
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const navigate = useNavigate();

    const [value, setValue] = useState(new Date());


    console.log(eventId,addEdit)
    return(
        <div>
            <PrimaryAppBar/>
            <h2 className="header-Text">{addEdit} Event</h2>
            <div className="event-manage-body">
            <Paper sx={{width: '100%', overflow: 'hidden' }} elevation={3}>
                <div className="event-manage-body-content">
                <TextField
                    sx={{ width: '80%' }}
                    margin="normal"
                    required
                    fullWidth
                    id="eventName"
                    label="Event Name"
                    name="eventName"
                    // autoComplete="email"
                    autoFocus
                />
                <TextField
                    sx={{ width: '80%' }}
                    margin="normal"
                    required
                    fullWidth
                    id="eventTagline"
                    label="Event Tagline"
                    name="eventTagline"
                    // autoComplete="email"
                    // autoFocus
                />
                <TextField
                    sx={{ width: '80%' }}
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    id="eventTagline"
                    label="Event Summary"
                    name="eventTagline"
                    // autoComplete="email"
                    // autoFocus
                /><br/><br/>
                 <Grid container spacing={3} sx={{ width: '80%' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={6}>
                        <DatePicker
                        label="Event Date"
                        value={value}
                        onChange={(newValue) => {
                        setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                        label="Showtime"
                        value={value}
                        onChange={(newValue) => {
                        setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: '100%' }}/>}
                        />
                    </Grid>
                    </LocalizationProvider>
                 </Grid>
                 <Grid container spacing={1} sx={{ width: '80%', paddingTop: '15px' }}>
                    <Grid item xs={6}>
                    <label htmlFor="poster-upload">
                        <Input accept="image/*" id="poster-upload" multiple type="file" />
                        <Button variant="contained" component="span" startIcon={<PhotoIcon/>}>
                        Upload Poster
                        </Button>
                    </label>
                    </Grid>
                    <Grid item xs={6}>
                    <label htmlFor="poster-upload">
                        <Input accept="image/*" id="poster-upload" multiple type="file" />
                        <Button variant="contained" component="span" startIcon={<PhotoSizeSelectActualIcon/>}>
                        Upload Banner
                        </Button>
                    </label>
                    </Grid>
                 </Grid>
                 <Grid container spacing={1} sx={{ width: '80%', paddingTop: '15px' }}>
                    <Grid item xs={6}>
                    <Paper sx={{width: '100%', overflow: 'hidden', height: '20px' }} elevation={2}>

                    </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper sx={{width: '100%', overflow: 'hidden', height: '20px' }} elevation={2}>

                        </Paper>
                    </Grid>
                 </Grid>
                 <br/>
                 <Divider variant="middle" />
                 <br/>
                 <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Ticket Categories</Typography>
                    </Grid>
                 </Grid>
                 <Grid container spacing={1}>
                    <Grid item xs={8}>
                    <Button variant="contained" color="success" onClick={handleClickOpen}
                    sx={{float:'right', marginRight: '50px'}}>
                    Add New
                    </Button>
                    <div className="table-body">
                    
                    <TableContainer sx={{ maxHeight: 540 }}>
                    <Table stickyHeader> 
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>#</StyledTableCell>
                                    <StyledTableCell>Ticket Name</StyledTableCell>
                                    <StyledTableCell>Ticket Price</StyledTableCell>
                                    <StyledTableCell>Ticket Quantity</StyledTableCell>
                                    <StyledTableCell>Summary</StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover tabIndex={-1}>
                                    <TableCell>1</TableCell>
                                    <TableCell>VIP</TableCell>
                                    <TableCell align="right">5,000.00</TableCell>
                                    <TableCell align="right">50</TableCell>
                                    <TableCell>VIP Area</TableCell>
                                    <TableCell align="center">
                                    <ButtonGroup aria-label="outlined primary button group">
                                    <IconButton aria-label="edit" disabled={false} color="primary"
                                    onClick={handleClickOpen}>
                                        <BorderColorIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="error">
                                        <DeleteIcon/>
                                    </IconButton>
                                    <Switch defaultChecked={true} color="success" disabled/>
                                    </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                    </Table>
                    </TableContainer>                    
                    </div>
                    </Grid>
                 </Grid>
                 <Grid container spacing={1} sx={{ width: '80%', paddingTop: '15px' }}>
                    <Grid item xs={6}>
                    <Stack spacing={2} direction="row">
                        <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        <Button variant="contained" color="success">
                            Submit
                        </Button>
                    </Stack>
                    </Grid>
                 </Grid>
                </div>
            </Paper>
            </div>

            {/* ticket details dialog */}
            <Dialog open={open} onClose={handleClose}
                    fullWidth={'sm'}
                    >
                <DialogTitle>Ticket Details</DialogTitle>
                <DialogContent>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We
                    will send updates occasionally.
                </DialogContentText> */}
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Ticket Name"
                    type="text"
                    // fullWidth
                    variant="standard"
                /><br/>
                <TextField
                    // autoFocus
                    margin="dense"
                    id="name"
                    label="Ticket Price"
                    type="text"
                    // fullWidth
                    variant="standard"
                /><br/>
                <TextField
                    // autoFocus
                    margin="dense"
                    id="name"
                    label="Ticket Quantity"
                    type="text"
                    // fullWidth
                    variant="standard"
                /><br/>
                <TextField
                    // autoFocus
                    margin="dense"
                    id="name"
                    label="Ticket Summary"
                    type="text"
                    // fullWidth
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="error" variant="outlined">Cancel</Button>
                <Button onClick={handleClose} color="success" variant="outlined">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}