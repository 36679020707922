import React, { Component, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import PrimaryAppBar from "../components/appBar";
import { GetBookings } from "../../api-services/apiServices";
import { textAlign } from "@mui/system";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Reports() {
  const [bookings, setBookings] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let mounted = true;
    GetBookings().then((data) => {
      if (mounted) {
        if (data.status !== 401) {
          setBookings(data.data);
          setDataLoaded(true);
        } else {
          navigate("/login");
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  if (!dataLoaded) {
    return (
      <div style={{ paddingTop: "50px" }}>
        <PrimaryAppBar />
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "50px" }}
        >
          <CircularProgress />
        </Box>
      </div>
    );
  } else {
    const totalPrice = bookings.reduce(
      (total, currentItem) => (total = total + currentItem.totalTicketPrice),
      0
    );
    const totalTickets = bookings.reduce(
      (total, currentItem) => (total = total + currentItem.ticketCount),
      0
    );
    const totalIssuedTickets = bookings.reduce(
      (total, currentItem) => (total = total + currentItem.issuedCount),
      0
    );

    return (
      <div>
        <PrimaryAppBar />
        <h2 className="header-Text">Reports</h2>
        <div className="center-div">
          <Paper sx={{ width: "90%", overflow: "hidden", padding: "5px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h4>Total Tickets : {totalTickets}</h4>
                <h4>
                  Total Sale :{" "}
                  {numberWithCommas(totalPrice.toFixed(2).toLocaleString())}
                </h4>
              </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={3}>
                <h4>Ticket Category</h4>
                <h4>Ticket Category</h4>
              </Grid>
              <Grid item xs={2}>
                <h4>Ticket Count</h4>
                <h4>Ticket Count</h4>
              </Grid>
            </Grid> */}
          </Paper>
        </div>
        <div className="table-body">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 540 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>#</StyledTableCell>
                    <StyledTableCell>Ref Code</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Mobile</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Ticket Type</StyledTableCell>
                    <StyledTableCell>Ticket Count</StyledTableCell>
                    <StyledTableCell>Promo Type</StyledTableCell>
                    <StyledTableCell>Promotion</StyledTableCell>
                    <StyledTableCell>Total Price</StyledTableCell>
                    <StyledTableCell>Created At</StyledTableCell>
                    <StyledTableCell>Issue/Pending</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, idx) => (
                      <TableRow hover tabIndex={-1}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{row.refcode}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{row.mobileNo}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.seatCategories}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row.ticketCount}
                        </TableCell>
                        <TableCell align="center">
                          {!row.promotionType ? "-" : row.promotionType}
                        </TableCell>
                        <TableCell align="center">
                          {!row.promotionDescription
                            ? "-"
                            : row.promotionDescription}
                        </TableCell>
                        <TableCell align="right">
                          {row.totalTicketPrice.toFixed(2).toLocaleString()}
                        </TableCell>
                        <TableCell>{row.createdAt}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row.issuedCount} /{" "}
                          {row.ticketCount - row.issuedCount}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="success"
                            disabled={row.isTicketsIssued}
                          >
                            Issue
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    {/* <TableCell rowSpan={1} /> */}
                    <TableCell
                      colSpan={6}
                      align="right"
                      sx={{ fontWeight: "bold" }}
                    >
                      Total
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                      {totalTickets}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      {totalPrice.toFixed(2).toLocaleString()}
                    </TableCell>
                    <TableCell rowSpan={4} align="right" />
                    <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                      {totalIssuedTickets} / {totalTickets - totalIssuedTickets}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    );
  }
}
