import * as React from "react";

export async function LoginCall() {
  const url = `${process.env.REACT_APP_DEV_BASE_URL}/auth/token`;
  const requestOptions = (token) => {
    return {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: localStorage.getItem("userName"),
        password: localStorage.getItem("password"),
      }),
    };
  };
  const data = await fetch(url, requestOptions());
  if (data.status === 401) {
    return data;
  } else {
    return await data.json();
  }
}

export async function GetBookings() {
  const accessToken = localStorage.getItem("accessToken");
  const url = `${process.env.REACT_APP_DEV_BASE_URL}/organizers/events/bookings`;
  const requestOptions = (token) => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };
  const data = await fetch(url, requestOptions());
  if (data.status === 401) {
    return data;
  } else {
    return await data.json();
  }
}

export async function GetMyEvents() {
  const accessToken = localStorage.getItem("accessToken");
  const url = `${process.env.REACT_APP_DEV_BASE_URL}/organizers/events`;
  const requestOptions = (token) => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };
  const data = await fetch(url, requestOptions());
  return await data.json();
}

export async function GetRefs() {
  const accessToken = localStorage.getItem("accessToken");
  const ref = localStorage.getItem("refCode");
  const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/booking?refcode=${ref}`;
  const requestOptions = (token) => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };
  const data = await fetch(url, requestOptions());
  if (data.status === 401) {
    return data;
  } else {
    return await data.json();
  }
}

export async function IssueTicket() {
  const accessToken = localStorage.getItem("accessToken");
  const url = `${process.env.REACT_APP_DEV_BASE_URL}/organizers/events/tickets/issue`;
  const requestOptions = (token) => {
    return {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        transactionId: localStorage.getItem("transactionId"),
        seatNo: localStorage.getItem("seat"),
      }),
    };
  };
  // console.log(requestOptions);
  const data = await fetch(url, requestOptions());
  if (data.status === 401) {
    return data;
  } else {
    return await data.json();
  }
}
