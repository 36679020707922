import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { QrReader } from "react-qr-reader";

import { GetRefs, IssueTicket } from "../../api-services/apiServices";

import PrimaryAppBar from "../components/appBar";
import { useNavigate } from "react-router-dom";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    fontWeight: "bold",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function IssueTickets() {
  const [refCode, setRefCode] = useState("No result");
  const [bookingData, setBookingData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [issued, setIssued] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [warnOpen, setWarnOpen] = useState(false);
  const [error, setError] = useState(false);
  const [qrCam, setQrCam] = useState(false);

  const navigate = useNavigate();

  const getRefCode = (event) => {
    setRefCode(event.target.value);
  };

  const clearText = () => {
    setRefCode("");
    setDataLoaded(false);
    setError(false);
  };

  const checkRefCode = () => {
    let mounted = true;
    console.log(refCode);
    if (refCode !== "") {
      localStorage.setItem("refCode", refCode);
      GetRefs().then((data) => {
        if (mounted) {
          console.log(data.data);
          if (data.status !== 401) {
            setBookingData(data.data);
            setDataLoaded(true);
            setError(false);
          } else {
            setWarnOpen(true);
            setError(true);
            // navigate("/login");
          }
        }
      });
      console.log(bookingData);
      return () => {
        mounted = false;
      };
    } else {
      setErrorOpen(true);
      setError(true);
    }
  };
  console.log(bookingData);

  const issueTicket = (tId, seat) => {
    console.log(tId, seat);
    localStorage.setItem("transactionId", tId);

    console.log(seat);
    if (seat === undefined) {
      localStorage.setItem("seat", null);
    } else {
      localStorage.setItem("seat", seat);
    }
    let mounted = true;
    IssueTicket().then((data) => {
      if (mounted) {
        console.log(data);
        setIssued(data);
        if (data.status !== 401) {
          setOpen(true);
          checkRefCode();
        } else {
          navigate("/login");
        }
      }
    });
    console.log(issued);
    return () => {
      mounted = false;
    };
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const errorHandleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const warnHandleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      return;
    }
    setWarnOpen(false);
  };

  const camOnOff = () => {
    setQrCam(!qrCam);
  };

  const successToast = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const ErrorToast = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={errorHandleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const WarnToast = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={warnHandleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <PrimaryAppBar />
      <h2 className="header-Text">Issue Tickets</h2>
      <div className="table-body">
        <Paper sx={{ width: "100%", overflow: "hidden", padding: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Reference Code"
                type="text"
                variant="outlined"
                value={refCode}
                onChange={getRefCode}
                error={error}
              />
              {qrCam ? (
                <QrReader
                  onResult={(result, error) => {
                    if (!!result) {
                      setRefCode(result?.text);
                    }

                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  constraints={{ facingMode: "environment" }}
                  style={{ maxWidth: "50px" }}
                />
              ) : null}
              {/* <p>{refCode}</p> */}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "15px", float: "left" }}>
              <Stack spacing={2} direction="row" sx={{ margin: 1 }}>
                <Button
                  color="success"
                  variant="contained"
                  onClick={checkRefCode}
                >
                  Check
                </Button>
                <Button color="error" variant="contained" onClick={clearText}>
                  Clear
                </Button>
                <Button
                  variant="outlined"
                  color={qrCam ? "error" : "info"}
                  onClick={camOnOff}
                  startIcon={<CameraAltIcon />}
                >
                  On/Off
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <br />
        </Paper>
        <br />

        {dataLoaded ? (
          <Paper sx={{ width: "100%", overflow: "hidden", padding: "15px" }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Stack spacing={2} direction="column" sx={{ margin: 1 }}>
                  <h3>Ref Code : {bookingData.refcode}</h3>
                  <Button
                    color="success"
                    variant="contained"
                    disabled={bookingData.ticketsIssued}
                    onClick={() => issueTicket(bookingData.id)}
                  >
                    Issue All
                  </Button>
                  <h4>Name : {bookingData.user}</h4>
                  <h4>Mobile : {bookingData.mobile}</h4>
                  <h4>Email : {bookingData.email}</h4>
                  <h4>
                    Total Amount :{" "}
                    {numberWithCommas(
                      bookingData.totalTicketPrice.toFixed(2).toLocaleString()
                    )}
                  </h4>
                  <h4>Total Tickets : {bookingData.seatList.length}</h4>
                </Stack>
                <br />
                {bookingData.seatList.map((seat) => (
                  <Stack spacing={2} direction="row" sx={{ margin: 1 }}>
                    <Button
                      color="success"
                      variant="contained"
                      disabled={seat.issued}
                      onClick={() => issueTicket(bookingData.id, seat.seatNo)}
                    >
                      {seat.seatType + ' - ' + seat.seatNo}
                    </Button>
                  </Stack>
                ))}
              </Grid>
            </Grid>
          </Paper>
        ) : null}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message=""
        action={successToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Ticket issued
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={errorHandleClose}
        message=""
        action={ErrorToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={errorHandleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Reference Code can't be Blank
        </Alert>
      </Snackbar>

      <Snackbar
        open={warnOpen}
        autoHideDuration={6000}
        onClose={warnHandleClose}
        message=""
        action={WarnToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={warnHandleClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Please Enter a Valid Reference Code
        </Alert>
      </Snackbar>
    </div>
  );
}
